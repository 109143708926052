import * as React from "react"
import { Subtitle } from "../../layout/Subtitle"
import { Title } from "../../layout/Title"

export function Angebote() {
  return (
    <>
      <Title title="Meine Angebote für DICH/EUCH:" />
      <ol>
        <li><b>Life Coaching / Lebens- und Sozialberatung</b></li>
        <li><b>Bioresonanz / Organetik</b></li>
        <li><b>Trance / Higher Self / Proxy Healing - Energieübertragungstools</b></li>
        <li><b>New Spirit - spirituelle Beratung</b></li>
        <li><b>"Verbindungsraum" - Infos, Tools, Techniken & Wissensbox</b></li>
        <li><b>Disclaimer</b></li>
    </ol>
<p>
    <strong>
        Für alle Angebote gilt - bitte Storno 24 h vor deinem Termin!
    </strong>
</p>
<p>
    <strong>1. Life Coaching / Lebens- und Sozialberatung</strong>
</p>
<p>
    <em>
        "Ich bin der Meister meines Schicksals. Ich bin der Kapitän meiner
        Seele."
    </em>
</p>
<p align="right">
    (Zitat: William Ernest Henley)
</p>
<p>
    Life Coaching auch genannt Lebens- und Sozialberatung sowie psychosoziale +
    psychologische Beratung bildet die 4. Säule in unserer Gesundheitsvorsorge.
    Der Unterschied zur Psychotherapie ist, dass es in der Lebensberatung
    hauptsächlich um die Unterstützung sowie Begleitung in Krisensituationen,
    in Lebenskrisen sowie oder auch um Umbrüche im Leben geht. Sie dient unter
    anderem auch zur Vorbeugung und Bewusstseinsbildung, um gegebenenfalls eine
    Hilfestellung in persönlichen sowie auch in akuten Krisen zu geben, damit
    gesundheitliche Störungen bestenfalls gar nicht erst entstehen können. Der
    Life Coach / Lebens- und Sozialberaterin arbeitet mit „psychisch gesunden“
    Menschen, kann aber begleitend neben Experten/innen auf ihrem/seinem
    Fachgebiet in Anspruch genommen werden.
</p>
<p>
    Druck, Stress, Perfektions- und Leistungsanspruch, das ständige
    Funktionieren und dies meist für Andere, Unterdrücken der Gefühle und
    Bedürfnisse gehört für viele im Leben schon ganz automatisch dazu. Es ist
    jedoch keine gesunde Lebenseinstellung auf Dauer, um mit sich gesund in
    Balance bleiben zu können.
</p>
<p>
    Bewältigung von Problemen sowie Disharmonien in Beziehungen,
    Lebensgemeinschaften, Familien oder generell in Beziehungen können für dich
    im privaten sowie beruflichen Umfeld zusätzliche Belastungen auslösen
</p>
<p>
    Meistens zeigen sich DEINE Probleme ja schon relativ lange in verschiedenen
    Gewändern, ob körperlich, energetisch, emotional und/oder in nicht endenden
    Gedankenstrudel, die sich im Kreis bewegen und dich vielleicht, dann auch
    nicht mehr schlafen lassen?! Es kommt immer mehr und mehr dazu, bis du
    endlich bereit bist hinzuschauen und DIR zuzuhören!!!
</p>
<p>
    <em>
        „Deine unterdrückten Schatten können der direkte Weg zu DIR sein. Es
        ist dein Geburtsrecht DICH zu leben, dafür bist du hier.“
    </em>
</p>
<p>
    Kennst du das? Dein Körper reagiert, deine Emotionen fahren Achterbahn, du
    kommst einfach nicht mehr zur Ruhe, dein Herz schreit? Du fühlst dich schon
    lange müde und angeschlagen und kommst einfach nicht mehr zur Ruhe und in
    deine Kraft? Das könnten alles Anzeichen dafür sein, dass du für andere,
    aber schon lange nicht mehr für dich selbst lebst!?
</p>
<p>
    Genau hier möchte ich mit dir ansetzen, zu erkennen was deine Anteile für
    deine Situation sind, wie du DU frühzeitig die Situation verändern auf dich
    hören und handeln kannst.
</p>
<p>
    Selbst- und Fremdbild – wie nehme ich die Welt und mein Umfeld wahr und wie
    die anderen? Warum versteht mich einfach keiner oder hört mir zu? Im
    „Verbindungsraum“ höre ich dir zu – hier wird DIR die Aufmerksamkeit
    geschenkt, die du dir vielleicht schon lange verweigert hast, aber dringend
    benötigst. Gemeinsam finden wir heraus, was dich belastet und du brauchst
    sowie welche für dich die nächst möglichen Schritten sein könnten!
</p>
<p>
    Mein Angebot an DICH ist DIR Raum &amp; Zeit für DICH und DEINE Themen zur
    Verfügung zu stellen! Deinen Gefühlen inkl. deiner Schatten sowie auch
    deinen Problemen Platz zu geben und sichtbar werden zu dürfen, um nicht
    mehr automatisch „weggedrückt“ oder „ignoriert“ oder „schöngeredet“ zu
    werden. Mit einer ehrlichen, authentischen Beratung möchte ich dich
    unterstützen eine ehrliche Verbindung zu DIR und deinem Inneren
    wiederherzustellen.
</p>
<p align="right">
    (Text Quelle privat + Netzfund)
</p>
<p>
    Das Beratungssetting kann einzeln, als Familie oder Gruppe genützt werden.
    Gerne persönlich (unter den aktuellen vorgegebenen Sicherheits- und
    Hygienemaßnahmen) in der Beratung oder per Telefon, Skype, Zoom!
</p>
<p>
    <strong><em>Austausch: </em></strong>
    € 85 für 1 h / € 125 für 1,5 h
</p>
<p>
    <strong>2. Bioresonanz / ORGANETIK</strong>
</p>
<p>
    <em>
        "Der Schmerz oder eine Krankheit sind der Schrei des Gewebes nach
        fließender Energie."
    </em>
</p>
<p align="right">
    (Quelle unbekannt, Chinesisches Sprichwort)
</p>
<p>
    ORGANETIK ist ein Bioresonanzverfahren, wo der Mensch (jedes Alters) von A
    – Z nach Energie- bzw. Schwingungsblockaden auf verschiedenen Ebenen
    durchgecheckt wird, zB die Drüsen, die Organe, die Wirbelsäule, die
    Chakren, das Nervensystem und viele weitere Bestandteile des Körpers.
</p>
<p>
    In der ORGANETIK geht man davon aus, dass jede Krankheit eine Ursache hat
    und, dass diese auch energetisch sein kann! Denn Energie ist Schwingung und
    eine Schwingung transportiert Informationen. Es wird oft vergessen, dass
    der Körper aus Energie besteht, das heißt auch, dass sich dort verdichtete 
    Energien und dazugehörige Informationen speichern und deine Gesundheit
    blockieren können. Wenn sich im Körper blockierte Energie staut, kann sich
    dies als zB Schmerz, Krankheit, Schlafstörung oder auch in
    Unverträglichkeiten zeigen.
</p>
<p>
    Genauso enthalten jedes Wort, jeder Gedanke sowie jede Emotion eine
    Schwingung &amp; Information für dein System. Manchmal sind wir in
    negativen Gedankenspiralen und/oder Emotionsschleifen gefangen und hat,
    dann noch über sich oder andere nicht so nette Worte oder Gedanken. Und
    wenn man nun davon ausgeht, dass alles eine Schwingung hat, kann mich sich
    vorstellen, was das in einem auslösen kann und dadurch dein System, deine
    Balance/Mitte und die Gesundheit ins Schwanken kommen könnte.
</p>
<p>
    Kleines Beispiel zum Veranschaulichen: Du brauchst nur an Stress zu denken
    oder was du noch alles zum Erledigen hast – was machst das mit dir, deinem
    Körper – wie fühlt es sich an?? Was löst es aus? Gedanke – Emotion –
    Schwingung!
</p>
<p>
    Neben dem Körper wird auch auf Geist und Seele eingegangen, denn emotionale
    Belastungen wie Überreizung, Wutausbrüche, Ängste, Sorgen, Panikattacken
    usw. – aber auch Stressbelastungen, Schlafprobleme und auch traumatische
    Belastungen können unsere Gesundheit aus dem Gleichgewicht bringen und auf
    verschiedenen Ebenen blockieren.
</p>
<p>
    <em>
        „Das wichtigste Prinzip ist die Aktivierung deiner Selbstheilungskräfte
        und wenn möglich dem Ursprung auf die Schliche kommen!“
    </em>
</p>
<p>
    Alle Belastungen bzw. Blockaden sowie deren Eigenschwingung, die wir finden
    beheben wir mit der ORGANETIK einen sogenannten Bioresonanzverfahren.
</p>
<p>
    <strong><em>Ablauf: </em></strong>
    Man wird ganz unkompliziert mit einer Kontaktplatte und einem Kabel mit der
    ORGANETIK verbunden. Diese Kontaktplatte kannst du dir bestenfalls auf den
    Bauch liegen oder natürlich auch woanders möglich, so dass wir die
    belastenden Energien mit Hilfe der ORGANETIK lösen können. Zur Feststellung
    der einzelnen Belastungen wird das Unterbewusstsein von mir als
    „Zertifizierter Organetikerin by ORGANO“ mit einem Sensor abgefragt. Das
    Unterbewusstsein ist ein riesiger Speicher, den der Mensch hat, dieser ist
    auch vergleichbar mit einem Fehlerspeicher von einem Auto. Der Mechaniker
    schließt zur Kontrolle das Auto in der Werkstatt an einem PC an, und kann
    sofort auslesen, wo die einzelnen Fehler gespeichert sind. Deshalb fragen
    wir das Unterbewusstsein ab, weil dort alles Positive sowie auch Negative
    abgespeichert ist, wie bei einer Speicherkarte. Der ORGANO Sensor ist das
    Abfragegerät und zeigt mir dann mit „JA“ oder „NEIN“ die Antworten aus dem
    Unterbewusstsein an.
</p>
<p>
    Es kann durchaus sein, dass mehrere ORGANETIK-Sitzungen benötigt werden,
    dass man die Harmonisierungsabläufe öfter und im Intervall wiederholen
    sollte. Der Abstand zwischen den ORGANETIK-Sitzungen ist individuell zu
    klären, aber im Idealfall nicht zu weit auseinander! Man kann in der
    Erstsitzung schwer einschätzen, wie viele ORGANETIK-Sitzungen
    schlussendlich nötig sein werden!
</p>
<p align="right">
    (Quelle: Text privat und teilweise Organo SL)
</p>
<p>
    Die ORGANETIK-Sitzung kann vor Ort in der Praxis sowie auch aus der Ferne
    oder online gemacht werden! Die Erstsitzungen sollten jedoch bestenfalls
    vor Ort stattfinden!
</p>
<p>
    <strong><em>Austausch: </em></strong>
    € 85 für 1 h, € 125 für 1,5 h
</p>
<p>
    <strong>3. Trance / Higher Self / Proxy Healing</strong>
</p>
<p>
    Als <strong>Elevation Coach</strong> by „Bahar Yilmaz &amp; Jeffrey
    Kastenmüller“ durfte ich die wunderbaren Tools zur Energieübertragung in
    Form von Trance Healing, Higher Self Healing und Proxy Healing erlernen.
    Dabei möchte ich dich unterstützen deine Selbstheilungskräfte wieder zu
    aktivieren, dir deine Selbstverantwortung für DICH wieder bewusst machen,
    durch liebevolle Selbstreflexion, um wieder in deine wundervolle Verbindung
    zur DIR, deinem Höheres Selbst sowie deinem Geistigen Team /Geistigen Welt
    zu kommen. Die Energieübertragung soll dich dabei unterstützen wieder in
    dein inneres Gleichgewicht zu kommen und bestenfalls deine energetischen,
    körperlichen, seelischen Blockaden zu lösen. Es können sich auch Schatten
    zeigen, die dich immer wieder aus deiner Mitte bringen oder dich blockieren
    und gemeinsam können wir dann daran arbeiten, damit „unterdrücktes“
    sichtbar werden darf und angestaute Energie in deinem System wieder fließen
    kann.
</p>
<p>
    Das Trance Healing ist ein „Energieübertragungstool“, wo EXPLIZIT die für
    dich abgestimmte Energie aus der Geistigen Welt oder beim Higher Self
    Healing von deinem „Höheren Selbst“ an dich über mich als Medium übertragen
    wird.
</p>
<p>
    Vorinformationen zu deinen gewünschten Themen (außer es betrifft die Punkte
    unten im Vermerk!!) sind nicht notwendig! Die Arbeit übernehmen in diesem
    Fall die „Experten“ (dein „Höheres Selbst“, „die geistige Welt“, „dein
    geistiges Team“) und je neutraler ich bin, umso besser funktioniert die
    Übertragung.
</p>
<p>
    Es kann eigentlich bei fast allen Beschwerden eingesetzt werden. Es können
    die blockierten Energien wieder zum Fließen gebracht und die Schmerzen
    gelindert werden. Bei der Übertragung werden die „Energien“ automatisch
    dorthin gelenkt, wo sie gebraucht werden und der Ursprung sein könnte. Es
    kann durch die Harmonisierung dein System, Körper, Geist &amp; Seele wieder
    in Balance gebracht werden. Das Ziel ist es immer deine persönlichen
    Selbstheilungskräfte zu aktivieren &amp; zu stärken sowie dir auch
    aufzuzeigen, welche Heilungstrigger noch vorhanden sind und wo noch
    „Arbeit“ passieren darf.
</p>
<p>
    Das Trance Healing sowie das Higher Self Healing ist für alle Lebewesen
    (Mensch, Tier) jedes Alters möglich! Ausnahmen siehe Vermerk unten!
</p>
<p>
    Was ist der Unterschied zwischen Trance und Higher Self Healing? Bei Trance
    Healing wird die Energie aus der geistigen Welt an dich übertragen und beim
    Higher Self Healing verbinden wir dich wieder mit deinem Höheren Selbst und
    die Energie wird von dort auf dich übertragen! Durch ein gemeinsames
    Gespräch finden wir für DICH das aktuell richtige Energieübertragungstool
    heraus!
</p>
<p>
    <strong><em>Ablauf: </em></strong>
    In Normalfall findet das Trance sowie Higher Self Healing über die Ferne
    statt. Bei Bedarf vor Ort auch möglich. Die Wirkung ist vor Ort sowie über
    die Ferne bei beiden Varianten dieselbe! Bei der Fern-Variante nimmst du
    dir bitte zum vereinbarten Zeitpunkt ca. 30 min Zeit in ruhiger und
    angenehmer Atmosphäre im Liegen oder Sitzen. Ich als Medium stimme mich
    vorab ein und begebe mich in eine leichte Trance und stelle mich für dich
    als Übertragungskanal zur Verfügung und du darfst die wundervolle Energie
    empfangen &amp; genießen. Währenddessen darfst DU wieder die Verbindung zu
    dir selbst finden, spüren &amp; wahrnehmen.
</p>
<p>
    In der Sitzung finden sowohl die Energieübertragung auf dich statt sowie
    die Informationen bzw. Impulse, die ich erhalten und wahrnehmen werde.
    Gerne kombiniere ich zum ganzheitlichen Ansatz verschiedene Tools/Methoden
    zur Unterstützung deiner Entfaltung und deines Potenzials. Hinterher werde
    ich mich mit dir über die Impulse austauschen. Es gibt auch die
    Möglichkeiten an Fragen zu stellen, um von den „Experten“ Antworten zu
    erhalten. Ich arbeite mit verschiedenen Abfragetools und man kann zum
    Beispiel abfragen: was aktuell deine größte Blockade ist? Was deine
    seelisch Lernaufgabe ist? Welche Healing-Impulse aktuell benötigt werden
    und noch vieles mehr!
</p>
<p>
    Wichtig ist die Geistige Welt oder das Höhere Selbst öffnen Räume für
    Wachstum &amp; Veränderung. Für einen langfristigen Erfolg einer
    Energieübertragung ist es wichtig in die Selbstverantwortung zu kommen
    &amp; notwendige Veränderungen zu integrieren.
</p>
<p>
    Das Trance Healing sowie das Higher Self Healing können bis zu 3-4 Wochen
    nachwirken. Wichtig ist viel frisches Wasser in den nächsten 3-4 Tagen zu
    trinken und Ruhezeiten für dich zum Verarbeiten einbauen.
</p>
<p>
    Ein Trance / Higher Self Healing kann man bis zu ca. 3-4-mal im Jahr in
    Anspruch nehmen mit einem Abstand von ca. 2-3 Monaten. Man sollte sich
    immer Zeit zum Verarbeiten &amp; Wahrnehmen geben. Du könntest dich das
    ganze Jahr über mit den empfohlenen Abständen von dieser magischen Energie
    begleiten lassen!
</p>
<p>
    <strong><em>Vermerk: </em></strong>
    Mit der Buchung bestätigst du mir, dass du nicht unter Drogen/Alkohol oder
    sonstigen Substanzen stehst sowie keine bewusstseinsverändernden
    Medikamente zu dir nimmst. Für Schwangere wird bis zum 6. Monat
    grundsätzlich von Trance / Higher Self Healings abgeraten – ab den 6. Monat
    auch nur in Ausnahmefällen – bitte um vorige Kontaktaufnahme und um
    ausnahmslos selbstständige Mitteilung an mich über deine Schwangerschaft!
    Das Trance / Higher Self Healing gilt als Unterstützungsangebot und ersetzt
    keine/n Experten/in auf seinem/ihrem Fachgebiet. Dass du zur Kenntnis
    nimmst, dass getroffene Aussagen, Antworten aus den Abfragetools natürlich
    ohne Gewähr sind sowie keine Zukunftsprognosen oder Heilversprechen
    abgegeben werden oder sind!
</p>
<p align="right">
    (Quelle: Privat, teilweise Netzfund + Bahar Yilmaz)
</p>
<p>
    <strong><em>Austausch: </em></strong>
    € 85 für 1 h, € 125 für 1,5 h inkl. Vor- und Nacharbeitszeit
</p>
<p>
    <strong>Proxy Healing</strong>
</p>
<p>
    Beim Proxy Healing wird wie beim Trance Healing die EXAKT benötigte
    „Energie“ aus der Geistigen Welt durch mich als Medium auf einen Gegenstand
    sprich Proxy übertragen. Dies ist eine erprobte mediale Methode aus dem
    englischen Spiritualismus. Das Proxy ist demnach ein energetischer
    Informationsträger für dich PERSÖNLICH! Durch diese Technik der
    energetischen Harmonisierung von Körper, Geist und Seele durften schon
    viele chronische Beschwerden &amp; Blockaden verbessert oder gelöst werden.
</p>
<p>
    <strong><em>Ablauf: </em></strong>
    Bei Buchung verbinde ich mich mit der Geistigen Welt für das Kind/den
    Erwachsenen oder das Tier, wofür die „Energie“ benötigt wird, um die
    Energie direkt auf den Gegenstand deinem „Stellvertreter“(Proxy)
    raufzuladen. Auch hier gilt Beschwerden müssen nicht bekannt gegeben
    werden, außer es betrifft den Vermerk im Trance / Higher Self Healing.
    Danach wird der Gegenstand umgehend gut verpackt an dich verschickt.
</p>
<p>
    <strong><em>WICHTIG: </em></strong>
    Es sollte NUR von der Person für die das Proxy ist ausgepackt und berührt
    werden, ansonsten erlischt die Information am Proxy! Ausnahme bei Kindern,
    da dürfen es Eltern und bei Tierbesitzer der Besitzer auch berühren. Das
    Proxy darf gewaschen werden – es entlädt sich nur bei Fremdberührungen! Es
    sollte dann so oft wie möglich an die Körper bzw. Beschwerdestelle gelegt
    oder bei sich getragen werden.
</p>
<p>
    Die Abspeicherung hält über einen längeren Zeitraum an. Neue Aufladung ist
    immer wieder möglich falls notwendig! Ich verwende gerne kleine Handtücher,
    um es regelmäßig auf die Stelle legen zu können und diese auch nachts gut
    nützbar sind oder Steine, welche man in Hosen- oder Jackentaschen gut
    tragen oder auch unter den Kopfpolster legen kann. Es können aber auch
    gerne persönliche Gegenstände Lieblings-Stofftier des Kindes, Schmuckstücke
    etc. auf Wunsch aufgeladen werden.
</p>
<p>
    Proxy Healing eignet sich besonders gut bei „chronischen“ Beschwerden, weil
    es über einen längeren Zeitraum immer wieder auf die schmerzenden Stellen
    gelegt werden kann und für Kinder &amp; Tiere, weil es ihnen meist schwerer
    fällt für ca. 30 min still liegen zu bleiben!
</p>
<p>
    Bei einer Buchung (siehe Vemerk bei Punkt 3. Trance / Higher Self Healing -
    Energieübertragungstool) gelten dieselben Voraussetzungen &amp;
    Bedingungen!
</p>
<p>
    <strong><em>Austausch: </em></strong>
    € 85 inkl. Handtuch oder Stein + Vor- und Nacharbeitszeit
</p>
<p>
    <strong>4</strong>
    . <strong>New Spirit – spirituelle Beratung</strong>
</p>
<p>
Als <strong>New Spirit Coach</strong> und    <strong> sensitive &amp; mediale Beraterin</strong> by „Bahar Yilmaz und
    Jeffrey Kastenmüller“, unterstütze ich dich auf deinem spirituellen Weg,
    deinem spirituellen Zugang sowie deiner individuellen Verbindung zu DIR,
    bei der Schattenarbeit und bei der Erkennung deiner
    Selbstheilungsmöglichkeiten sowie deiner Selbstbestimmung und
    Selbstverantwortung!
</p>
<p>Du fragst Dich wahrscheinlich - Was habe ich als "New Spirit Energy Consultant – sensitive & mediale Beraterin" zu geben:</p>
<ul className="p-0 mb-3 dashed">
    <li>Reading/Lesen von Energiefeldern wie Aura,  InnerAura, Chakras, Dan Tian, Sushumna Kanal, …., Wohnungen, Häuser & Grundstücke sowie auch Lesen in deinem Seelenbuch</li>

    <li>Erkennen und Begleitung deiner persönlichen spirituellen Möglichkeiten & Fähigkeiten sowie von Abhängigkeiten &Wunden in verschiedenen Bereichen, welche dich dabei blockieren können -  Abfragen von Blockaden & Potenzialen in verschiedenen Bereichen mit
    verschiedenen Austestungsmöglichkeiten</li>

    <li>„Holografisches & Higher Self Healing“</li>

    <li>diverse Techniken : „Delinking“ (Auflösung von toxischen Verbindungen – Menschen, Situationen usw.), „Remote Viewing“ (Zeitreisen zu Auslösungsmomenten), „DNA-Upgrade“( es speichern sich extrem viel Konflikte & Energien in deiner DNA, die dich blockieren
    könnten), Schattenarbeit, Auflösung von Initialen Konflikten & Ängsten sowie von Energetischen Kollapsen, Erkennen von „Weak Spots“ (persönliche Energielöcher/-fresser) & toxische Emotionen sowie von Fremdenergien, Austestung von Herzenswänden – die Verbindung
    zu dir oder in vielen anderen Bereichen blockieren können und noch vieles mehr…</li>

    <li>Die Macht deiner Schatten sowie Verwundbarkeit</li>

    <li>Unterstützung & Coaching bei Energetischer Fitness & Selbstentfaltung</li>

    <li>Gerne für Details auf Anfrage bei mir melden!</li>
</ul>
<p>
    <strong><em>Ablauf: </em></strong>
    In einer Einzelsitzung arbeite ich ganz individuell – je nachdem, welches
    Thema du mitbringst. Anfangs lese ich meist dein Energiefeld, versuche die
    Ursachen deiner Blockade zu erspüren sowie auch für eventuelle negative
    Einflüsse und/oder blockierender Verbindungen, beantworte ich deine Fragen,
    aktiviere bestenfalls deine Selbstheilungskräfte und finde dein Potential
    und deine Stärken heraus. Im Vordergrund steht deine Verbindung zu DIR, der
    geistigen Welt, deinem geistigen Team, dem höheren Selbst und deinem Weg
    sowie was dich dabei blockieren könnte! Ich gebe dir Informationen, Tipps
    &amp; Möglichkeiten im Bedarfsfall an deine Hand und/oder eine Session /
    Meditation was deinen Heilungsprozess unterstützen könnte!
</p>
<p>
    Diese Angebote können über die Ferne, Online sowie Live vor Ort genützt
    werden
</p>
<p align="right">
    (Quelle: Privat, teilweise Netzfund + Bahar Yilmaz)
</p>
<p>
    <strong><em>Austausch: </em></strong>
    € 85 für 1 h, € 125 für 1,5 h inkl. Vor- und Nacharbeitszeit
</p>
<p>
    Bei einer Buchung (siehe Vemerk bei Punkt 3. Trance / Higher Self Healing -
    Energieübertragungstool) gelten dieselben Voraussetzungen &amp;
    Bedingungen!
</p>
<h2>
    5.“Verbindungsraum“ – Infos, Tools &amp; Techniken und Wissensbox
</h2>
<p>
    Das Konzept vom „Verbindungsraum“ ist es Beratung und Energiearbeit, im
    ganzheitlichen Ansatz miteinander zu verbinden!
</p>
<p>
    Ich arbeite mittlerweile mit vielen verschiedenen Techniken sowie auch
    Wissen, die ich mir im Laufe meiner Ausbildungen angeeignet und gelernt
    habe und auch noch lernen werde! Es macht es mir fast unmöglich hier alle
    aufzuzeigen. Mittlerweile schöpfe ich aus einer wundervollen Wissensbox,
    mit ganz viel Techniken &amp; Tools, die auch gerne miteinander verbinde
    zum Besten Wohl meiner Klienten/innen. In meiner bisherigen Laufbahn seit
    jetzt über 10 Jahren, hat sich einiges an Erfahrungen, an Tools &amp;
    Techniken angesammelt, welche ich individuell und sensitiv verwende. Die
    Verbindung von Gesprächen &amp; Energiearbeit und den Tools sind sehr
    erfolgreich, wirken gut ineinander und können befreiend sowie unterstützend
    sein.
</p>
<p>
    Meine Herzensmission ist es DICH wieder mit DIR und all deinen Teilen und
    Möglichkeiten zu VERBINDEN und DICH auf dem Weg in die Selbstheilung,
    Selbstbestimmung sowie auch Selbstverantwortung und in ein für dich
    gesünderes Bewusstsein zu begleiten. Mir ist es wichtig, dass du dich
    wieder spüren lernst und wieder ein Gefühl für dich entwickelst, dadurch
    wirst du automatisch viel sensitiver für dich sowie auch für dein Umfeld
    und erkennst auch die gesunden Grenzen, die man manchmal zum eigenen Wohl
    ziehen muss.
</p>
<p>
    „DEINE VERBINDUNG ZU DIR SOLLTE UNVERHANDELBAR SEIN!“
</p>
<p>
    Wenn du bereit bist DIR wieder zu begegnen, DICH wieder mit DIR zu
    verbinden, dir ZEIT, GEDULD und RAUM für die Prozesse einzuräumen, um zu
    deiner besten und gesündesten Version aufzublühen, dann freue ich mich DICH
    auf deiner persönlichen <em>„Verbindungsmission“</em> begleiten &amp;
    unterstützen zu dürfen!
</p>
<p align="right">
    (Quelle: Privat)
</p>
<p>
    <strong><em>Austausch: </em></strong>
    € 85 für 1 h, € 125 für 1,5 h inkl. Vor- und Nacharbeitszeit
</p>
<h2>
    6.Disclaimer für all meine Angebote: 
</h2>
<p>
    <strong>
        Meine Beratungen sowie Angebote ersetzen keine/n Arzt/in,
        Heilpraktiker/in, Psychiater/in, Psychotherapeuten oder sonstige/n
        Experten/in auf seinem/ihrem Fachgebiet. Die von mir gegebenen
        Hilfestellungen bzw. Angebote stellen keinen Ersatz für
        schulmedizinische Maßnahmen, Behandlungen oder Therapien. Jegliche
        Aussagen, Antworten, Empfehlungen, Angebote sind ohne Gewähr. Sie
        können begleitend und unterstützend sein, um gemeinsam mit der
        Schulmedizin oder Therapiemaßnahmen Hand in Hand zu gehen. Jeder
        Klient/in ist selbst verantwortlich für das Hinzuziehen und in Anspruch
        nehmen eines/m Experten/in. Ich gebe weder Heilversprechen,
        Zukunftsprognosen ab, noch stelle ich Diagnosen oder gebe welche im
        schulmedizinischen Sinne ab. Bitte beachten Sie bei Buchung meiner
        Angebote, zusätzlich den Vermerk bei Punkt 3. Healing –
        Energieübertragungstool. Jegliche Haftung mir gegenüber ist hiermit
        ausgeschlossen. Jeder Termin beruht auf freiwilliger Basis und setzt
        eine psychische Grundstabilität und Selbstverantwortung voraus.
    </strong>
</p>
    </>
  )
}
