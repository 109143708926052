import * as React from 'react';
import { Layout } from '../components/layout/Layout';
import { Angebote } from '../components/pages/angebote/Angebote';

export default function AngebotePage () {
  return (
    <Layout>
      <Angebote/>
    </Layout>
  );
}
